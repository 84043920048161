/* Common styles for all screen sizes */
.card2 {
  width: 345px;
  height: 659px;
  flex-shrink: 0;
  border-radius: 11.667px;
  border: 1px solid #fff;
  background: #fff;
}

.div32 {
  padding-left: 37px;
  padding-top: 24px;
  height: 163px;
  background: #00c6ff;
}
.for {
  color: #262626;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.month {
  margin: 0;
  margin-left: 20px;
  padding: 0;
}
.buttonbss2 {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.fort {
  margin-top: 0;
  margin-left: 37px;
}
/* Tablet (768px and below) */
@media (max-width: 768px) {
  .card2 {
    width: 85%;
    /* height: auto; */
    margin: 20px auto;
  }

  .div32 {
    padding-left: 20px; /* Adjust padding for smaller screens */
    height: auto; /* Adjust height as needed for content */
  }
}

/* Mobile (576px and below) */
@media (max-width: 576px) {
  .card2 {
    width: 90%;
    height: auto;
    margin: 20px auto;
  }

  /* Additional styles specific to mobile */
  .div32 {
    padding-left: 10px;
    padding-top: 10px;
    height: auto;
  }
  .fort {
    margin-left: 10px;
  }
}
.inputt {
  width: 16px;
  height: 16px;
}
