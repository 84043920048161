.Footer {
  background: #fff;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 2%;
  position: relative;
  overflow: hidden;
}

.footer-img {
  position: relative;
}
.footer-img img{
  max-width: 100%;
}

.footer-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.footer-leftimg img {
  width: 195px;
  height: 195px;
  flex-shrink: 0;
}
.imgdownconth1 {
  margin: 0;
  padding: 0;
}
.imgdownconttp {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 558px;
  color: #636363;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.footer-right {
  color: black;
  padding: 20px;
}

.divuu {
  display: flex;
  gap: 160px;
}

.firstList {
  flex: 1;
}

.divuh3 {
  color: #262626;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.divuul {
  list-style-type: none;
  padding: 0;
}

.divuul li {
  width: 136px;
  margin-bottom: 8px;
  color: #575757;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.inputtype {
  display: flex;
  margin-bottom: 26px;
 
  flex-shrink: 0;
}
input {
  height: 44px;
  width: 336px;
  border: none;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  background: #f5f5f5;
}
input ::placeholder {
  color: #636880;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn {
  cursor: pointer;
  margin-left: 10px;
  border: none;
  color: white;
  border-radius: 5px;
  background: #00c6ff;
  width: 153px;
  height: 44px;
  flex-shrink: 0;
}
.add-logoimg {
  width: 9px;
  height: 9px;
  flex-shrink: 0;
  background: #000;
}

.div3ull {
  list-style: none;
  padding: 0;
  color: #575757;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
}
.div3ull li {
  margin-left: 10px;
}

.fb-logo,
.twit-logo,
.insta-logo,
.yt-logo {
  display: flex;
  align-items: center;
}
.div3uh3 {
  width: 84px;
  color: #262626;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.goo-playstore img {
  width: 30px;
  border-radius: 50%;
}
.goo-playstore {
  text-decoration: none;
  background: #000;
  color: white;
  border-radius: 15px;
  width: 179px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.google-apple-btn {
  margin-left: 350px;
  cursor: pointer;
}
.contnt-playstr {
  margin-left: 20px;
  margin-right: 15px;
}
.apple {
  margin-left: 20px;
}
.google-icn {
  margin-left: 10px;
}
.playstr22 {
  font-size: 8px;
  margin: 0;
  padding-top: 8px;
}
.playstr23 {
  margin: 0;
  padding-bottom: 10px;
}
.two-google-app-storebtn {
  display: flex;
}
.gettt {
  color: #262626;
  font-family: "Roboto Condensed";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}



/* Tablet screen */

@media screen and (min-width: 564px) and (max-width: 768px) {

  .footer-img img {
    height: 700px;
  }
  .footer-content{
    display: block;
    top: 50%;
    left: 55%;
  }
  .footer-right{
    padding: 0;
  }
  .divuu{
    gap: 0;
  }

}

/* Laptop screens */
@media screen and (min-width: 769px) and (max-width: 1024px) {

  .footer-img img {
    height: 700px;
  }
  .footer-content{
    display: block;
    top: 50%;
    left: 55%;
  }
  .footer-right{
    padding: 0;
  }
  .divuu{
    gap: 0;
  }

}

/* Mobile screen (I-Phone ) */

@media screen and (max-width: 563px){

  .imgdownconth1{
    font-size: 22px;

  }
  .imgdownconttp{
    max-width: 90%;
    font-size: 12px;
  }

  .footer-img img {
    height: 800px;
  }
  .footer-content{
    display: block;
    text-align: center;
    top: 50%;
    left: 55%;
  }
  .footer-right{
    padding: 0;
    text-align: start;
    margin-left: 90px;
  }
  .divuu{
    gap: 0;
  }
  input{
    width: 250px;
  }
  .inputtype{
    display: block;
  }
  .btn{
    margin-top: 15px;
    margin-left: 0;
  }
  .firstList{
    flex: 0;
  } 
  .google-apple-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 80%;
  }
  .two-google-app-storebtn{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .apple{
    margin-left: 0;
    margin-top: 5px;
  }
  .goo-playstore{
    width: 280px;
  }
}
