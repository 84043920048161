.page6 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .content {
    text-align: center;
    margin-top: 20px; /* Adjust the margin as needed */
  }
  
  .main-cont {
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
  }
  
  .para {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%; /* Make sure the text is responsive */
    color: rgba(99, 104, 128, 1);
  }
  
  .footer {
    margin-top: 20px; /* Adjust the margin as needed */
  }
  
  .conth1 {
    text-align: center;
    margin-top: 20px; /* Adjust the margin as needed */
  }
  
  /* Responsive styles for smaller screens */
  @media (max-width: 768px) {
    .main-cont {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }
  }
  