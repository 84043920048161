.discoveryContainer{
    width: 80%;
    margin: auto;
    padding-top: 38px;
    .stepperWrapper{
        display: flex;
        .events li { 
            display: flex; 
            color: #999;
          }
          
          .events time { 
            position: relative;
            padding: 0 1.5em;  }
          
          .events time::after { 
             content: "";
             position: absolute;
             z-index: 2;
             right: 0;
             top: 0;
             transform: translateX(50%);
             border-radius: 50%;
             background: #fff;
             border: 1px #ccc solid;
             width: .8em;
             height: .8em;
          }
          
          
          .events span {
            padding: 0 1.5em 1.5em 1.5em;
            position: relative;
          }
          
          .events span::before {
             content: "";
             position: absolute;
             z-index: 1;
             left: 0;
             height: 100%;
             border-left: 1px #ccc solid;
          }
          
          .events span::before :last-child{
            border: none;
          }

          .events strong {
             display: block;
             font-weight: bolder;
          }
          
          .events { margin: 0; width: 50%; }
          .events, 
          .events *::before, 
          .events *::after { box-sizing: border-box; font-family: arial; }
          
    }
}