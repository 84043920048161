.para{
    color: #636880;
font-family: Nunito;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
}
.footer{
    margin-top: 610px;
}