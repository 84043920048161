.Logout {
  position: relative;
  height: 100vh;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.LogoutCard {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 5px;
  max-width: 90%;
  width: 570px;
  height: 515px;
  flex-shrink: 0;
  z-index: 1;
}

.overlay-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
}

.btn12 {
  width: 156px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #f2f2f2;
  border: none;
}
.btn11 {
  margin-left: 23px;
  width: 156px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #00c6ff;
  border: none;
  outline: none;
}
.logout-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* resposiveness */

@media (max-width: 768px) {
  .LogoutCard {
    width: 90%; /* Adjust width for smaller screens */
  }
}

@media (max-width: 480px) {
  .LogoutCard {
    width: 95%; /* Further adjust width for smaller screens */
    text-align: center;
  }
  .logout-button {
    flex-direction: column;
  }
  .btn11 {
    margin-top: 15px;
    margin-left: 0;
    width: 300px;
  }
  .btn12 {
    width: 300px;
  }
}
