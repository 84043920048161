.textAreaContainer{
    width: 100%;
    text-align: left;
    display: flex;
  flex-direction: column;
    textarea{
        height: 100px;
    }
    .heading{
        color: #161E29;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
    }
}
textarea{
    width: 100%;
    padding: 18px;
    border-radius: 10px;
    vertical-align: top;
    overflow-y: auto;   
    resize: none;
    border: 1px solid rgba(82, 49, 104, 0.60);
}
textarea:focus{
    outline: none;
}